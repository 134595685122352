<section class="section section-lg">
        <div class="container">
            <div class="main-container bg-color-gray-lightest">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="block__textBox">
                            <h2 class="block__Title  ">See us in action</h2>
                            <p class="Block__Paragraph  ">To explore our technology solutions to improve your business.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="ui-container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div
                                class="autowidthcolumnctrl section col-lg-12 col-md-12 col-sm-12 col-xs-12 col-lg-offset-0 col-md-offset-0 col-sm-offset-0">

                                <div class="auto-width-column col-sm-12 col-md-offset-1" id="contact-us__quick-links">

                                    <div class="awc-three-thirtythree-c0 col-sm-6 col-md-4 col-xs-12">
                                        <div class="quicklinkcomponent section">

                                            <div id="f5d7cd009d3d4fb6a4c9e296af95cd27" class="card_display"
                                                data-cq-id="f5d7cd009d3d4fb6a4c9e296af95cd27">
                                                <div class="icon-bg">
                                                    <div class="circle-over-icon">
                                                        <i class="icon-graphic-o"></i>

                                                    </div>
                                                </div>

                                                <div class="text-container ">
                                                    <h4>Technology Assessment</h4>
                                                    <div class="quicklink-desc">
                                                        <p>See us in action. How do you control cloud costs while maintaining
                                                            the speed and independence of your development teams</p>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="awc-three-thirtythree-c2 col-sm-6 col-md-4 col-xs-12">
                                        <div class="quicklinkcomponent section">


                                            <div id="345f8166dc5442feb802ca1b30545736" class="card_display"
                                                data-cq-id="345f8166dc5442feb802ca1b30545736">
                                                <div class="icon-bg  ">
                                                    <div class="circle-over-icon">
                                                        <i class="icon-phone-o"></i>
                                                        <!--
                                                        <img alt="" src="/content/dam/shared/icons/submit-rfp.svg">
                                                    -->
                                                    </div>
                                                </div>

                                                <div class="text-container ">
                                                    <h4>Request our Services</h4>
                                                    <div class="quicklink-desc">
                                                        <p>We appreciate your interest in ForeTx. ForeTx deliver specialized capabilities and solutions to clients across multiples industries.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!--
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="block__textBox">
                            <h1 class="block__Title">Contact us</h1>
                            <p class="Block__Paragraph">Do you have any questions? Please do not hesitate to contact us
                                directly. Our team will come back to you within a matter of hours to help you.
                            </p>
                        </div>

                    </div>
                </div>-->
                <div class="row">
                    <div class="col-md-3">
                        <div class="contact-info">
                            <img src="https://image.ibb.co/kUASdV/contact-image.png" alt="image"/>
                            <h3 class="contact_label">Contact Us</h3>
                            <h5 class="contact_label">We would love to hear from you</h5>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="contact-form">
                            <div class="form-group">
                            <label class="control-label col-sm-2" for="fname">First Name:</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" id="fname" placeholder="Enter First Name" name="fname">
                            </div>
                            </div>
                            <div class="form-group">
                            <label class="control-label col-sm-2" for="lname">Last Name:</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" id="lname" placeholder="Enter Last Name" name="lname">
                            </div>
                            </div>
                            <div class="form-group">
                            <label class="control-label col-sm-2" for="email">Email:</label>
                            <div class="col-sm-10">
                                <input type="email" class="form-control" id="email" placeholder="Enter email" name="email">
                            </div>
                            </div>
                            <div class="form-group">
                            <label class="control-label col-sm-2" for="comment">Comment:</label>
                            <div class="col-sm-10">
                                <textarea class="form-control" rows="5" id="comment"></textarea>
                            </div>
                            </div>
                            <div class="form-group">
                            <div class="col-sm-offset-2 col-sm-10">

                                <input type="submit" value="Send Message" class="btn btn-primary rounded-0 py-2 px-4">
        <span class="submitting"></span>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 text-center">
                        <ul class="list-unstyled mb-0">
                            <li><i class="fas fa-map-marker-alt fa-2x contact_icon"></i>
                                <p class="contact_label">Monterrey, Nuevo León, Mexico</p>
                            </li>

                            <li><i class="fas fa-phone mt-4 fa-2x contact_icon"></i>
                                <p class="contact_label">+ 52 8121 4175 23</p>
                            </li>

                            <li><i class="fas fa-envelope mt-4 fa-2x contact_icon"></i>
                                <p class="contact_label">contact@foretx.com</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
</section>
