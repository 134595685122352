<main>

  <div class="position-relative">
    <!-- shape Hero -->
    <section class="section section-shaped pb-250">
      <div class="shape shape-style-1 shape-default">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container py-lg-md d-flex">
        <div class="col px-0">
          <div class="row">
            <div class="col-lg-6">
              <h1 class="display-3  text-white">Tech is everywhere
              </h1>
              <p class="lead  text-white">Will you watch the world change around you? Or be the one leading it?
                Transform, automate and grow with technology services that power the New IT</p>
              <div class="btn-wrapper">
                <a
                  class="btn btn-info btn-icon mb-3 mb-sm-0"
                  href="/industries">
                  <span class="btn-inner--text">Industries</span>
                </a>
                <a class="btn btn-white btn-icon mb-3 mb-sm-0"
                   href="services#sectionTech">
                  <span class="btn-inner--icon"><i class="ni ni-album-2"></i></span>
                  <span class="btn-inner--text">Read More</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- SVG separator -->
      <div class="separator separator-bottom separator-skew">
        <svg preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" xmlns="http://www.w3.org/2000/svg"
             y="0">
          <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </section>
    <!-- Technology Cars -->
  </div>
  <section class="section section-lg pt-lg-0 mt--200">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="row row-grid">
            <div class="col-lg-4">
              <div class="card card-lift--hover shadow border-0">
                <div class="svc card-body py-5">
                  <div class="icon icon-shape icon-shape-primary rounded-circle mb-4">
                    <i class="ni ni-app"></i>
                  </div>
                  <h6 class="text-primary text-uppercase">Technology Consulting</h6>
                  <p class="description mt-3">ForeTX is a digital accelerator. We combine deep industry knowledge with
                    the highest technological expertise in the market to create tailored solutions to overcome your
                    business challenges and create disruptive growth.</p>
                  <div>
                    <span class="badge badge-pill badge-primary">agile</span>
                    <span class="badge badge-pill badge-primary">dev ops</span>
                    <span class="badge badge-pill badge-primary">architecture</span>
                  </div>
                  <a class="svc-btn btn btn-primary mt-4" href="services#sectionTech">view more</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card card-lift--hover shadow border-0">
                <div class="svc card-body py-5">
                  <div class="icon icon-shape icon-shape-success rounded-circle mb-4">
                    <i class="ni ni-cloud-upload-96"></i>
                  </div>
                  <h6 class="text-success text-uppercase">Cloud</h6>
                  <p class="description mt-3">The cloud is not a destination, but an experience. Our approach is based
                    on business needs first, creating industry-specific
                    solutions for you to become digital and take all the benefits from the cloud, now.</p>
                  <div>
                    <span class="badge badge-pill badge-success">migration</span>
                    <span class="badge badge-pill badge-success">security</span>
                    <span class="badge badge-pill badge-success">cost-efficiency</span>
                  </div>
                  <a class="svc-btn btn btn-success mt-4" href="services#sectionCloud">view more</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card card-lift--hover shadow border-0">
                <div class="svc card-body py-5">
                  <div class="icon icon-shape icon-shape-warning rounded-circle mb-4">
                    <i class="ni ni-spaceship"></i>
                  </div>
                  <h6 class="text-warning text-uppercase">Operations</h6>
                  <p class="description mt-3">Whereas App development speeds up dramatically, QA must not get behind.
                    Keep your business focus on the creative things and let the DevOps world to take care about
                    continuous integrations, code quality, performance and site reliability engineering.</p>
                  <div>
                    <span class="badge badge-pill badge-warning">devOps</span>
                    <span class="badge badge-pill badge-warning">automation</span>
                    <span class="badge badge-pill badge-warning">SRE</span>
                  </div>
                  <a class="svc-btn btn btn-warning mt-4" href="services#sectionAuto">view more</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section-lg">
    <div class="position-relative">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-6 order-md-1">
            <h1 class="display-3">Evolve your business, <span class="text-primary">Join the transformation</span></h1>
            <p class="lead pb-4">We aim high at being focused on building relationships with our clients and
              community. Let us understand your business, the improvement potential it has, and we propose solutions
              to increase your profitability. Our mission is to transform our Clients business towards delivering
              different services through the Internet, including data storage, servers, databases, networking, and
              software on demand. We are turning the world into a digital one.</p>

            <div class="col-sm-6 col-6 pl-lg-0">
              <button class="btn btn-primary btn-block" type="submit">Contact Us</button>
            </div>
          </div>
          <div class="col-md-6 order-md-2"><img class="ml-lg-5 img-fluid floating" src="./assets/img/ill/ill.png"
                                                width="100%"></div>
        </div>
      </div>
    </div>
  </section>
  <section class="section section-lg pb-0 bg-gradient-blue-dark" id="sectionTech">
    <!-- SVG separator -->
    <div class="separator separator-top separator-skew zindex-100">
      <svg preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" xmlns="http://www.w3.org/2000/svg"
           y="0">
        <polygon class="fill-white" points="0 0 2560 0 0 100"></polygon>
      </svg>
    </div>
    <div class="container">
      <div align="right">
        <div class="icon icon-lg icon-shape bg-gradient-blue-dark shadow rounded-circle text-primary">
          <i class="ni ni-app text-primary"></i>
        </div>
      </div>
      <div class="row row-grid align-items-center">
        <div class="d-flex px-3">
          <div class="pl-4">
            <h4 class="display-3 text-white-50">Technology Consulting</h4>
            <article _ngcontent-rtn-c19="" class="module blockquote-container enable-border"
                     data-analytics-module-name="blockquotemodule">
              <blockquote _ngcontent-bii-c19="" class="quote-border solid-color-aqua">
                <div _ngcontent-bii-c19="" class="quote-description rte-inline">We provide the best <strong
                  class="text-white">technology </strong>and <strong class="text-white">enterprise solutions</strong> to
                  create <strong class="text-white">new experiences</strong></div>
              </blockquote>
            </article>
            <div class="quote-description">
              <p class="rte-justify"><span><strong class="text-white">Fore TX </strong> selects, implements and optimises the best technology and business solutions to accelerate the digital transformation process. We use our robust technological knowledge to create value and differentiate. Our work is supported by the best methods and tools.</span>
              </p>
              <p class="rte-justify">&nbsp;</p>
              <p class="rte-justify"><span><strong class="text-white">Data </strong>and <strong class="text-white">digital experience</strong> are fundamental to digital transformation and are more than ever designed with the customer in mind. We identify the aspirations, needs and desires of customers to build efficient and sustainable digital products over time.&nbsp;We create interfaces that trigger emotions, emotions that lead to action and realise company goals.</span>
              </p>
              <p class="rte-justify">&nbsp;</p>
              <p class="rte-justify"><span>We design solid architectures which form the basis of our solutions. Scalable solutions which can adapt to future growth and ongoing changes, making use of other data sources and integrating these seamlessly.</span>
              </p>
              <p class="rte-justify">&nbsp;</p>
              <p class="rte-justify"><span><strong class="text-white">Fore TX </strong> uses the best business solutions to optimise customer relations and internal processes, while maximising profits. We combine these solutions with the latest technology to provide the best solution for every business challenge: <strong
                class="text-white">IoT</strong>, <strong class="text-white">blockchain</strong>, and <strong
                class="text-white">new IT</strong> are some examples of the disruptive technologies which <strong
                class="text-white">Fore Tx </strong>uses to drive digital transformation.</span></p>
              <p class="rte-justify">&nbsp;</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- SVG separator -->
    <div class="separator separator-bottom separator-skew zindex-100">
      <svg preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" xmlns="http://www.w3.org/2000/svg"
           y="0">
        <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div>
  </section>

  <section>
    <div class="section features features-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8 text-center mx-auto">
            <h3 class="display-3">Everyone of us can make a difference, together we make change</h3>
            <p class="lead"> This is how we do the <strong class="display-5">fore</strong>front <strong
              class="display-5">t</strong>ransformation <strong class="display-5">e</strong>xperience </p>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-12 mt-md-5">
            <div class="row">
              <div class="col-md-6">
                <div class="info">
                  <div class="pr-md-5">
                    <div class="icon icon-shape icon-shape-primary shadow rounded-circle mb-4"><i
                      class="ni ni-atom"></i></div>
                    <h5 class="display-5">TRUE AGILE</h5>
                    <p>We rule by the principles of Agile methodology, working on efficient and adaptive sprints to
                      deliver quality solutions in time. We have specialized teams formed within the set of frameworks
                      and practices founded on the values and principles expressed in the Manifesto for Agile Software
                      Development to tackle any type of project regardless of size and complexity.</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info">
                  <div class="pr-md-5">
                    <div class="icon icon-shape icon-shape-primary shadow rounded-circle mb-4"><i
                      class="ni ni-money-coins"></i></div>
                    <h5 class="display-5">DIGITAL ARCHITECTURE</h5>
                    <p>We don’t just provide Digital Solutions; we create a Digital Ecosystem tailored to your company’s
                      unique situation that allows complete integration between Technology, your employees and your
                      users. This is what separates a quick-fix from a robust and long lasting solution that will
                      empower your business for years to come.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6">
                <div class="info">
                  <div class="pr-md-5">
                    <div class="icon icon-shape icon-shape-primary shadow rounded-circle mb-4"><i
                      class="ni ni-support-16"></i></div>
                    <h5 class="display-5">CULTURE TRANSFORMATION</h5>
                    <p>We transform the Digital Culture of your company to embrace change and harness innovation. Every
                      project starts with a Technology Maturity Assessment, which identifies Technological Pain Points
                      in your company which we target with Employee Specialized Training and Deep-dive workshops.</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info">
                  <div class="pr-md-5">
                    <div class="icon icon-shape icon-shape-primary shadow rounded-circle mb-4"><i
                      class="ni ni-laptop"></i></div>
                    <h5 class="display-5">TECHNOLOGY ENTHUSIASTS</h5>
                    <p>Passion is about deliberately bringing technology into your own life or the lives of others, and
                      not just using it because it’s there.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section section-lg pb-0 bg-gradient-green-light" id="sectionCloud">
    <!-- SVG separator -->
    <div class="separator separator-top separator-skew zindex-100">
      <svg preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" xmlns="http://www.w3.org/2000/svg"
           y="0">
        <polygon class="fill-white" points="0 0 2560 0 0 100"></polygon>
      </svg>
    </div>
    <div class="container">
      <div align="right">
        <div class="icon icon-lg icon-shape bg-gradient-green-light shadow rounded-circle text-success">
          <i class="ni ni-cloud-upload-96 text-success"></i>
        </div>
      </div>
      <div class="row row-grid align-items-center">
        <div class="d-flex px-3">
          <div class="pl-4">
            <h4 class="display-3 text-black">Cloud Ops</h4>

            <img class="img-fluid" src="./assets/img/services/technology-digital-transformation.png">

            <blockquote _ngcontent-bii-c19="" class="quote-border solid-color-aqua">
                <span><div _ngcontent-bii-c19="" class="rte-inline text-black ">&nbsp; Advisory
                  <p>&nbsp; initial evaluation, and strategic definition</p> </div></span>
            </blockquote>

            <p class="rte-justify">The <strong class="text-black"> adoption of the Cloud </strong> must be understood
              more as a point of departure for digital transformation willing to improve the business, than as a purely
              technological matter. The focus is not on how to adopt the Cloud, but how to improve the business thanks
              to the Cloud.</p>

            <blockquote _ngcontent-bii-c19="" class="quote-border solid-color-aqua">
                <span><div _ngcontent-bii-c19="" class="rte-inline text-black ">&nbsp; Implementation
                  <p>&nbsp; transformation to improve the business</p> </div></span>
            </blockquote>

            <p class="rte-justify">We take into account technological and non-technological perspectives, following best
              practices and policies to select the best reference model (IaaS, PaaS, SaaS) for each Application nd
              Service to minimize costs on migration and modernization and maximize cost-efficiency.</p>

            <blockquote _ngcontent-bii-c19="" class="quote-border solid-color-aqua">
                <span><div _ngcontent-bii-c19="" class="rte-inline text-black ">&nbsp; Continuous Evolution
                  <p>&nbsp; continuous cloud integration and delivery</p> </div></span>
            </blockquote>

            <p class="rte-justify">Within shortest development life cycles companies need agile development without
              compromising security, quality and performance. Taking advantage of cloud elasticity and monitoring. At
              Fore TX we offering agile, automated models which guarantees quality and speed at minimum cost. </p>

            <blockquote _ngcontent-bii-c19="" class="quote-border solid-color-aqua">
                <span><div _ngcontent-bii-c19="" class="rte-inline text-black ">&nbsp; Operations
                  <p>&nbsp; manage and monitor the cloud as a new environment</p> </div></span>
            </blockquote>

            <p class="rte-justify">Companies need people with the knowledge to fully control and exploit automation
              within the multi-cloud environment always with a cost-efficiency mindset. Our approach involves a defined
              process to train personnel for the new capacities.</p>

            <p class="rte-justify">&nbsp;</p>
          </div>
        </div>
      </div>
    </div>
    <!-- SVG separator -->
    <div class="separator separator-bottom separator-skew zindex-100">
      <svg preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" xmlns="http://www.w3.org/2000/svg"
           y="0">
        <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div>
  </section>

  <div class="features-5">
    <div class="col-md-8 ml-auto mr-auto text-center">
      <h2 class="title pb-5 mb-5">Why to adopt Cloud Computing?</h2>
    </div>
    <div class="container p-0">
      <div class="row">
        <div class="col-sm-3">
          <div class="info text-center">
            <div class="icon icon-lg icon-shape icon-shape-success shadow rounded-circle text-white">
              <i class="fa fa-mobile"></i>
            </div>
            <h5 class="info-title mt-4">competitiveness</h5>
            <p>
              It allows for greater flexibility in the deployment of services, which permits a “granular” focus in
              completing or replacing capacities.
              Speed, flexibility and scalability represent advantages for the Cloud scenario. New users can quickly and
              efficiently be included within an existing infrastructure.
            </p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="info text-center">
            <div class="icon icon-lg icon-shape icon-shape-success shadow rounded-circle text-white">
              <i class="fa fa-wrench"></i>
            </div>
            <h5 class="info-title mt-4">operational efficiency</h5>
            <p>
              It allows organizations to concentrate on the management of core processes, instead of dedicating a huge
              percentage of time and resources to the administration of its IT services.
            </p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="info text-center">
            <div class="icon icon-lg icon-shape icon-shape-success shadow rounded-circle text-white">
              <i class="fa fa-coins"></i>
            </div>
            <h5 class="info-title mt-4">financial flexibility</h5>
            <p>Cloud has a strong impact on company budgets: it improves the use of resources, reducing IT spending by
              between 20 and 80% from capital spending, labour cost and power and cooling consumption, depending on
              their nature, freeing up funds to invest in the core business.</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="info text-center">
            <div class="icon icon-lg icon-shape icon-shape-success shadow rounded-circle text-white">
              <i class="fa fa-code"></i>
            </div>
            <h5 class="info-title mt-4">innovation</h5>
            <p>Cloud Computing allows for the possibility to generate new income streams via the implementation of
              disruptive business models. </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section class="section section-lg pb-0 bg-gradient-orange-light" id="sectionAuto">
    <!-- SVG separator -->
    <div class="separator separator-top separator-skew zindex-100">
      <svg preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" xmlns="http://www.w3.org/2000/svg"
           y="0">
        <polygon class="fill-white" points="0 0 2560 0 0 100"></polygon>
      </svg>
    </div>
    <div class="container">
      <div align="right">
        <div class="icon icon-lg icon-shape bg-gradient-orange-light shadow rounded-circle text-warning">
          <i class="ni ni-spaceship text-warning"></i>
        </div>
      </div>
      <div class="row row-grid align-items-center">
        <div class="d-flex px-3">
          <div class="pl-4">
            <h4 class="display-3 text-warning">Operations</h4>
            <p class="rte-justify">&nbsp;</p>
            <p class="rte-justify"> There is a big difference between the things you make and how you make them. At
              ForeTX we operate focusing on Automation, Quality and Performance.</p>
            <p class="rte-justify">As technology goes forward, the adaptation to new trends is key. We are technology
              enthusiast always looking for better ways to perform technology solutions in the day to day.</p>
          </div>
        </div>
      </div>
    </div>
    <!-- SVG separator -->
    <div class="separator separator-bottom separator-skew zindex-100">
      <svg preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" xmlns="http://www.w3.org/2000/svg"
           y="0">
        <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div>
  </section>

  <section class="section section-lg">
    <div class="container">
      <div class="row row-grid align-items-center">
        <div class="col-md-6 order-md-2">
          <img class="img-fluid floating" src="assets/img/services/draft.png">
        </div>
        <div class="col-md-6 order-md-1">
          <div class="pr-md-5">
            <div class="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-5">
              <i class="ni ni-settings-gear-65"></i>
            </div>
            <h3>Forefront transformation experience</h3>
            <p>Yet your business may not be getting the full value from your investments. Our deep industry expertise
              puts us in a unique position to help you use the right technology to address your most complex and
              critical challenges—whether it’s through faster cloud migration, getting the most value out of the top
              technology platforms, making the most of your data through applied intelligence, or underpinning
              everything you do with security.</p>
          </div>
        </div>
      </div>
    </div>
  </section>

</main>
