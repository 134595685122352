<!-- footer -->
<footer id="footer">
    <div class="main-footer widgets-dark typo-light">
        <div class="container">
            <div class="row">

                <div class="col-xs-12 col-sm-6 col-md-3">
                    <div class="widget subscribe no-box">
                        <h5 class="widget-title">ForeTX Company<span></span></h5>
                        <p>Our mission is to transform businesses towards digital services, contributing to build a better society that combines technology and true human values. </p>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-3">
                    <div class="widget no-box">
                        <h5 class="widget-title">Services<span></span></h5>
                        <ul class="thumbnail-widget">
                            <li>
                                <div class="thumb-content"><a class="ni ni-app" href="#."> Technology Consulting</a></div>
                            </li>
                            <li>
                                <div class="thumb-content"><a class="ni ni-cloud-upload-96" href="#.">&nbsp;Cloud</a></div>
                            </li>
                            <li>
                                <div class="thumb-content"><a class="ni ni-spaceship" href="#.">&nbsp;Automation</a></div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-3">
                    <div class="widget no-box">
                        <h5 class="widget-title">Follow up<span></span></h5>

                        <a href="#"><fa-icon [icon]="['fab', 'facebook']" [styles]="{'stroke': 'white', 'color': 'white'}"></fa-icon></a>&nbsp;&nbsp;
                        <a href="#"><fa-icon [icon]="['fab', 'twitter']" [styles]="{'stroke': 'white', 'color': 'white'}"></fa-icon></a>&nbsp;&nbsp;
                        <a href="#"><fa-icon [icon]="['fab', 'linkedin']" [styles]="{'stroke': 'white', 'color': 'white'}"></fa-icon> </a>
                    </div>
                </div>
                <br>
                <br>
                <div class="col-xs-12 col-sm-6 col-md-3">
                    <div class="widget no-box">
                        <h5 class="widget-title">Contact Us<span></span></h5>
                        <p>contact@foretx.com</p>

                        <!--
                        <div class="emailfield">
                        <input type="text" name="email" value="Email">
                        <input name="uri" type="hidden" value="arabiantheme">
                        <input name="loc" type="hidden" value="en_US">
                        <input class="submitbutton ripplelink" type="submit" value="Send">
                        </div>-->
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-copyright">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <p>Copyright ForeTX Company© 2021. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--option 2
<div class="footer-dark">
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-md-6 item text">
                    <h3>Company Name</h3>
                    <p>Praesent sed lobortis mi. Suspendisse vel placerat ligula. Vivamus ac sem lacus. Ut vehicula rhoncus elementum. Etiam quis tristique lectus. Aliquam in arcu eget velit pulvinar dictum vel in justo.</p>
                </div>
                <div class="col-sm-6 col-md-3 item">
                    <h3>Services</h3>
                    <ul>
                        <li><a href="#">Cloud Solutions</a></li>
                        <li><a href="#">Web Development</a></li>
                        <li><a href="#">Cyber Security</a></li>
                    </ul>
                </div>
                <div class="col-sm-6 col-md-3 item">
                    <h3>About</h3>
                    <ul>
                        <li><a href="#">4TX</a></li>
                        <li><a href="#">Team</a></li>
                        <li><a href="#">Careers</a></li>
                    </ul>
                </div>

                <div class="col item social"><a href="#"><i class="icon ion-social-facebook"></i></a><a href="#"><i class="icon ion-social-twitter"></i></a><a href="#"><i class="icon ion-social-snapchat"></i></a><a href="#"><i class="icon ion-social-instagram"></i></a></div>
            </div>
            <p class="copyright">4TX© 2021 Todos los derechos reservados</p>
        </div>
    </footer>
</div>
-->
