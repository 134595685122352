<div class="container-fluid">
  <!--start slider-->
  <div class="carousel slide" data-ride="carousel" id="carouselExampleIndicators">
    <ol class="carousel-indicators">
      <li class="active" data-slide-to="0" data-target="#carouselExampleIndicators"></li>
      <li data-slide-to="1" data-target="#carouselExampleIndicators"></li>
      <li data-slide-to="2" data-target="#carouselExampleIndicators"></li>
    </ol>
    <div class="carousel-inner" role="listbox">
      <div class="carousel-item active">
        <div class="item bg-black-grey">

          <div class="cover">
            <div class="container">
              <div class="header-content">
                <div class="line"></div>
                <h2>Reinvent Business Experience with</h2>
                <h1>Our Global and Integrated Solutions</h1>
                <h4>We help entrepreneurs, start-ups and enterprises shape their ideas into business</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="item bg-black-grey">

          <div class="cover">
            <div class="container">
              <div class="header-content">
                <div class="line animated bounceInLeft"></div>
                <h2>Reinvent Digital Experience with</h2>
                <h1>Our Digital and Intelligent Solutions</h1>
                <h4>We help entrepreneurs, start-ups and enterprises shape their dreams into profits</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="item bg-black-grey">

          <div class="cover">
            <div class="container">
              <div class="header-content">
                <div class="line animated bounceInLeft"></div>
                <h2>Reinvent Client Experience with</h2>
                <h1>Cloud Native Solutions</h1>
                <h4>We help entrepreneurs, start-ups and enterprises shape their leads into clients</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" data-slide="prev" href="#carouselExampleIndicators" role="button">
      <span aria-hidden="true" class="carousel-control-prev-icon"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" data-slide="next" href="#carouselExampleIndicators" role="button">
      <span aria-hidden="true" class="carousel-control-next-icon"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
  <!--end slider-->
  <div class="ui-container bg-color-gray-lightest">
    <div class="row">
      <div class="col-xs-12">
        <div class="block-title">
          <h2 class="section-title col-lg-12 col-md-12 col-sm-12 col-xs-12">Join the transformation experience
            <hr>
          </h2>
        </div>
      </div>
      <div class="block-content col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-lg-offset-0 col-md-offset-0 col-sm-offset-0">
          <div class="dynamic-content-card-image-block col-xs-12 progressive first-load" data-analytics-module-name="dynamiccontentcardsimageblock"
               data-card-count="3" data-cards-lm="" data-cards-sm=""
               data-cards-xs="">
            <div class="image-card-per-row-lg col-lg-12 col-md-12">
              <div class="image-card-per-row-md">
                <div class="col-sm-6 col-md-4 image-card-container progressive-card">
                  <div class="dynamic-content-cards-image image-fold-module" id="new-lens-1">
                    <div class="img-container">
                      <a aria-label="Transformation"
                         data-analytics-content-type="engagement"
                         data-analytics-link-name="Business" data-linkaction="Business"
                         data-linktype="engagement"
                         href="/en-us/industry/cloud-business" tabindex="-1"
                         target="_self">
                        <img alt="Transformation"
                             class="adaptive-img marquee-image img-responsive lazy img-ratio-size"
                             data-imagelg="./assets/img/home/cloud-bussines.jpeg"
                             data-imagesm="./assets/img/home/cloud-bussines.jpeg"
                             data-imagexs="./assets/img/home/cloud-bussines.jpeg" data-src="./assets/img/home/cloud-bussines.jpeg"
                             src="./assets/img/home/cloud-bussines.jpeg"
                             style="height: 194.491px;">
                        <noscript>
                          <img alt="Transformation"
                               class="adaptive-img marquee-image img-responsive lazy"
                               src="./assets/img/home/cloud-bussines.jpeg"/>
                        </noscript>
                      </a>
                    </div>
                    <div class="content-container bg-color- ">
                      <div class="img-block-text-container">
                        <div>
                          <p class="article-topic ">
                            <span class="topic-link">Transformation</span>
                          </p>
                        </div>
                        <div class="head-container">
                          <a aria-label="Cloud your business"
                             data-analytics-link-name="Cloud your business"
                             data-linktype="engagement" href="/en-us/industry/cloud-business"
                             target="_self">
                            <h3 class="module-title  active">
                              Cloud your business
                            </h3>
                          </a>
                        </div>
                        <p class="card-description description-container dot-ellipsis rte-inline ddd-truncated">
                          The Cloud is not a destination, but an experience. Knowing and anticipating customer needs using the right channels is key. </p>
                      </div>
                      <div class="cta-container">
                        <a aria-label="VIEW MORE : Cloud your business" class="arrow cta-arrow"
                           data-analytics-link-name="VIEW MORE : Cloud your business"
                           data-linkaction="VIEW MORE"
                           data-linktype="engagement" data-original-title="" href="/en-us/industry/cloud-business"
                           target="_self">
                          View more <span>&#62;</span>

                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4 image-card-container progressive-card">
                  <div class="dynamic-content-cards-image image-fold-module" id="new-lens-2">
                    <div class="img-container">
                      <a aria-label="Innovation"
                         data-analytics-content-type="engagement" data-analytics-link-name="Innovation"
                         data-linkaction="Innovation" data-linktype="engagement"
                         href="/mx-es/industry/tech-industry" tabindex="-1" target="_self">
                        <img alt="Innovation"
                             class="adaptive-img marquee-image img-responsive lazy img-ratio-size"
                             data-imagelg="./assets/img/home/tech-industry.JPG"
                             data-imagesm="./assets/img/home/tech-industry.JPG"
                             data-imagexs="./assets/img/home/tech-industry.JPG" data-src="./assets/img/home/innovation-unctad.org.jpg"
                             src="./assets/img/home/innovation-unctad.org.jpg"
                             style="height: 194.491px;">
                        <noscript>
                          <img alt="Innovation"
                               class="adaptive-img marquee-image img-responsive lazy"
                               src="./assets/img/home/innovation-unctad.org.jpg"/>
                        </noscript>
                      </a>
                    </div>
                    <div class="content-container bg-color- ">
                      <div class="img-block-text-container">
                        <div>
                          <p class="article-topic ">
                            <span class="topic-link">Innovation</span>
                          </p>
                        </div>
                        <div class="head-container">
                          <a aria-label="Innovation"
                             data-analytics-link-name="Innovation" data-linktype="engagement" href="/mx-es/industry/tech-industry"
                             target="_self">
                            <h3 class="module-title  active">
                              Catching technological waves
                            </h3>
                          </a>
                        </div>
                        <p class="card-description description-container dot-ellipsis rte-inline">
                          Our specialist help companies to identify, twig and respond to trends in times of uncertainty.
                        </p>
                      </div>
                      <div class="cta-container">
                        <a aria-label="View more : Innovation" class="arrow cta-arrow"
                           data-analytics-link-name="View more : Innovation" data-linkaction="View more"
                           data-linktype="engagement" data-original-title="" href="/mx-es/industry/tech-industry"
                           target="_self">
                          View more <span>&#62;</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4 image-card-container progressive-card">
                  <div class="dynamic-content-cards-image image-fold-module" id="new-lens-3">
                    <div class="img-container">
                      <a aria-label="Respond to trends in times of uncertainty"
                         data-analytics-content-type="engagement"
                         data-analytics-link-name="Respond to trends in times of uncertainty" data-linktype="engagement"
                         href="/mx-es/industry/consumer-industry" tabindex="-1"
                         target="_self">
                        <img alt="Respond to trends in times of uncertainty"
                             class="adaptive-img marquee-image img-responsive lazy img-ratio-size"
                             data-imagelg="./assets/img/home/consumer-industry.jpg"
                             data-imagesm="./assets/img/home/consumer-industry.jpg"
                             data-imagexs="./assets/img/home/consumer-industry.jpg" data-src="./assets/img/home/company-build.JPG"
                             src="./assets/img/home/company-build.JPG"
                             style="height: 194.491px;">
                        <noscript>
                          <img alt="Respond to trends in times of uncertainty"
                               class="adaptive-img marquee-image img-responsive lazy"
                               src="./assets/img/home/company-build.JPG"/>
                        </noscript>

                      </a>
                    </div>
                    <div class="content-container bg-color- ">
                      <div class="img-block-text-container">
                        <div>
                          <p class="article-topic ">
                            <span class="topic-link">Digital</span>
                          </p>
                        </div>
                        <div class="head-container">
                          <a aria-label="Respond to trends in times of uncertainty"
                             data-analytics-link-name="Respond to trends in times of uncertainty"
                             data-linktype="engagement" href="/mx-es/industry/consumer-industry"
                             target="_self">
                            <h3 class="module-title active">
                              Accelerating towards industry
                            </h3>
                          </a>
                        </div>
                        <p class="card-description description-container dot-ellipsis rte-inline">
                          Prepare your business for the new normal. Let us take your business to the top and beyond.
                        </p>
                      </div>
                      <div class="cta-container">
                        <a aria-label="View more : Respond to trends in times of uncertainty"
                           class="arrow cta-arrow" data-analytics-link-name="View more : Respond to trends in times of uncertainty"
                           data-linkaction="View more"
                           data-linktype="engagement" data-original-title="" href="/mx-es/industry/consumer-industry"
                           target="_self">
                          View more <span>&#62;</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
