import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomePageComponent } from './home-page/home-page.component';
import { InsightsPageComponent } from './insights-page/insights-page.component';
import { ServicesPageComponent } from './services-page/services-page.component';
import { IndustriesPageComponent } from './industries-page/industries-page.component'; 
import { AboutUsPageComponent } from './about-us-page/about-us-page.component';
import { ContactUsPageComponent } from './contact-us-page/contact-us-page.component';

const appRoutes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'home', component: HomePageComponent},
  {path: 'insights', component: InsightsPageComponent},
  {path: 'services', component: ServicesPageComponent},
  {path: 'industries', component: IndustriesPageComponent},
  {path: 'about', component: AboutUsPageComponent},
  {path: 'contact', component: ContactUsPageComponent},
  {path: '**', redirectTo: '/home', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}