import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-page',
  templateUrl: './services-page.component.html',
  styleUrls: ['./services-page.component.css']
})
export class ServicesPageComponent implements OnInit {

  page = {
    title: 'Services',
    content: 'Some services content. Services-page works!'
  }

  constructor() { }

  ngOnInit(): void {
  }

}
