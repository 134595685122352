<div class="container-fluid mt-5">
  <div class="ui-container hero-module">
    <div
      class="about-hero-wrapper dynamic-height initiative program has-title has-intro has-cta has-texts has-breadcrumb"
      id="about-hero" data-analytics-module-name="aboutheroblock" data-analytics-template-zone="hero">
      <div class="item bg-color-white hero-initiative-program lazy module-container">

        <div class="row">
          <div class="block-content no-padding-topbottom" id="">
            <div class="col-xs-12">
              <article class="module">

                <div class="col-xs-12 col-sm-6 col-md-6 hero-content">

                  <h1 class="rte-inline col-xs-12 page-title page-title-long">ForeTx Technologies</h1>

                  <p class="rte-inline col-xs-12 about-hero-intro corporate-regular">
                    We provide advisory, consultancy and technology solutions mainly focused to the Could.
                    We do agile cost-efficiency driven advisory and consulting focusing on end-to-end business goals.
                    Always seeking for technologies trends and innovations that promote our clients to grow by achieving an efficiently and profitable technology transformation
                  </p>
                  <p class="rte-inline col-xs-12 about-hero-intro corporate-regular">
                    &nbsp;&nbsp;&nbsp;
                  </p>
                  <p class="rte-inline col-xs-12 about-hero-intro corporate-regular">
                    &nbsp;&nbsp;&nbsp;
                  </p>
                  <p class="rte-inline col-xs-12 about-hero-intro corporate-regular">
                    &nbsp;&nbsp;&nbsp;
                  </p>

                </div>
              </article>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 hidden-xs dynamic-bg image-background-banner"></div>
        </div>
      </div>
    </div>
  </div>
  <div id="block-sectionpagezoneonecolumn" class="ui-container lazy  bg-color-gray-lightest no-padding-topbottom"
    data-src="" data-analytics-template-zone="block-sectionpagezoneonecolumn">
    <div class="row no-padding-topbottom">
      <div class="block-content-second col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-lg-offset-0 col-md-offset-0 col-sm-offset-0">
          <div class="col-sm-12 col-xs-12">
            <article class="module blockquote-container enable-border " data-analytics-module-name="blockquotemodule">
              <blockquote class="quote-border solid-color-aqua">
                <div class="quote-description rte-inline">ForeTx adopts the power of the new technology for creating 360 degrees value while sharing success with Clients, people, partners and community
                </div>
              </blockquote>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="block-our-core-values" class="ui-container lazy bg-color-white" data-src=""
    data-analytics-template-zone="block-nuestros-valores">
    <div class="row ">
      <div class="col-xs-12">
        <div class="block-title">
          <h2 class="section-title col-lg-8 col-md-8 col-sm-12 col-xs-12">Values
            <hr>
          </h2>
        </div>
        <div class="col-xs-12">
          <div class="pagezone-description rte-inline col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <p>The pillars within our culture define us as a company. These values are materialized in our code behaviour from individuals to collaborative teams</p>
          </div>
        </div>

        <div class="block-content col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-lg-offset-0 col-md-offset-0 col-sm-offset-0">

            <div class="progressive col-xs-12 first-load" data-cards-xs="" data-cards-sm="" data-cards-lm=""
              data-card-count="6">
              <div class="stat-card-block-container module block-adjustment  col-sm-12 col-xs-12">

                <div class="stat-cards card-adjustment col-sm-12">

                  <article class="stat-card-container light col-sm-6 col-md-4 col-xs-12"
                    data-analytics-module-name="card1">
                    <div class="text-info-card progressive-card brand-color">

                      <div class="stat-content bg-color-gray-lightest">
                        <h3 class="module-title">Value creation</h3>

                        <p class="fluid rte-inline">
                          Help our clients and partners to become high performance companies by creating long term relationships within a constantly innovation delivery
                        </p>
                      </div>
                    </div>
                  </article>

                  <article class="stat-card-container light col-sm-6 col-md-4 col-xs-12"
                    data-analytics-module-name="card3">
                    <div class="text-info-card progressive-card brand-color">

                      <div class="stat-content bg-color-gray-lightest">
                        <h3 class="module-title">Team work and collaboration</h3>

                        <p class="fluid rte-inline">
                          We always keep special focus on ethics and transparency. We believe in honesty and communication to build true partnership
                        </p>
                      </div>
                    </div>
                  </article>

                  <article class="stat-card-container light col-sm-6 col-md-4 col-xs-12"
                    data-analytics-module-name="card5">
                    <div class="text-info-card progressive-card brand-color">

                      <div class="stat-content bg-color-gray-lightest">
                        <h3 class="module-title">Fairness</h3>

                        <p class="fluid rte-inline">
                          Foster an environment of trust where everyone can express their ideas freely. Promoting inclusion and acknowledging contributions
                        </p>
                      </div>
                    </div>
                  </article>

                  <article class="stat-card-container light col-sm-6 col-md-4 col-xs-12"
                    data-analytics-module-name="card2">
                    <div class="text-info-card progressive-card">
                      <div class="stat-band bg-color-card-brand"></div>
                      <div class="stat-content bg-color-gray-lightest">
                        <h3 class="module-title">The best professionals</h3>
                        <p class="fluid rte-inline">Attract, develop and retain the best talent into the company.
                          We take care of our professionals by providing tools to help them reach their full potential and most importantly to help them realize they belong to a great place to work</p>
                      </div>
                    </div>
                  </article>

                  <article class="stat-card-container light col-sm-6 col-md-4 col-xs-12"
                    data-analytics-module-name="card4">
                    <div class="text-info-card progressive-card">
                      <div class="stat-band bg-color-card-brand"></div>
                      <div class="stat-content bg-color-gray-lightest">
                        <h3 class="module-title">Efficiency</h3>

                        <p class="fluid rte-inline">
                          Combine cutting edge human talent, tools and technology to reduce complexity and tackle any challenge
                        </p>
                      </div>
                    </div>
                  </article>

                  <article class="stat-card-container light col-sm-6 col-md-4 col-xs-12"
                    data-analytics-module-name="card6">
                    <div class="text-info-card progressive-card">
                      <div class="stat-band bg-color-card-brand"></div>
                      <div class="stat-content bg-color-gray-lightest">
                        <h3 class="module-title">Socially responsible</h3>

                        <p class="fluid rte-inline">
                          We believe that the actions of our people must benefit the whole of society. We enforce the balance between economic growth and the welfare of society and the environment
                        </p>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="section section-lg">
  <div class="container">
    <div class="row justify-content-center text-center mb-lg">
      <div class="col-lg-8">
        <h2 class="display-3">The amazing Team</h2>
        <p class="lead text-muted">“Teamwork is the secret that makes common people achieve uncommon results” – Ifeanyi Enoch Onuoha</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-lg-3 mb-5 mb-lg-0">
        <div class="px-4">
          <img src="./assets/img/theme/team-4-800x800.jpg" class="rounded-circle img-center img-fluid shadow shadow-lg--hover" style="width: 200px;">
          <div class="pt-4 text-center">
            <h5 class="title">
              <span class="d-block mb-1">Romina Vega</span>
              <small class="h6 text-muted">Marketing Strategist</small>
            </h5>
            <div class="mt-3">
              <a href="javascript:void(0)" class="btn btn-success btn-icon-only rounded-circle">
                <i class="fa fa-twitter"></i>
              </a>
              <a href="javascript:void(0)" class="btn btn-success btn-icon-only rounded-circle">
                <i class="fa fa-facebook"></i>
              </a>
              <a href="javascript:void(0)" class="btn btn-success btn-icon-only rounded-circle">
                <i class="fa fa-dribbble"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 mb-5 mb-lg-0">
        <div class="px-4">
          <img src="./assets/img/theme/team-1-800x800.jpg" class="rounded-circle img-center img-fluid shadow shadow-lg--hover" style="width: 200px;">
          <div class="pt-4 text-center">
            <h5 class="title">
              <span class="d-block mb-1">Justin Bremer</span>
              <small class="h6 text-muted">UI/UX Designer</small>
            </h5>
            <div class="mt-3">
              <a href="javascript:void(0)" class="btn btn-warning btn-icon-only rounded-circle">
                <i class="fa fa-twitter"></i>
              </a>
              <a href="javascript:void(0)" class="btn btn-warning btn-icon-only rounded-circle">
                <i class="fa fa-facebook"></i>
              </a>
              <a href="javascript:void(0)" class="btn btn-warning btn-icon-only rounded-circle">
                <i class="fa fa-dribbble"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 mb-5 mb-lg-0">
        <div class="px-4">
          <img src="../../assets/img/theme/team-yoel-800x800.jpg" class="rounded-circle img-center img-fluid shadow shadow-lg--hover" style="width: 200px;">
          <div class="pt-4 text-center">
            <h5 class="title">
              <span class="d-block mb-1">Yoel Gallegos</span>
              <small class="h6 text-muted">Technology Manager</small>
            </h5>
            <div class="mt-3">
              <a href="javascript:void(0)" class="btn btn-info btn-icon-only rounded-circle">
                <i class="fa fa-twitter"></i>
              </a>
              <a href="javascript:void(0)" class="btn btn-info btn-icon-only rounded-circle">
                <i class="fa fa-facebook"></i>
              </a>
              <a href="javascript:void(0)" class="btn btn-info btn-icon-only rounded-circle">
                <i class="fa fa-dribbble"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 mb-5 mb-lg-0">
        <div class="px-4">
          <img src="./assets/img/theme/team-rini-800x800.jpg" class="rounded-circle img-center img-fluid shadow shadow-lg--hover" style="width: 200px;">
          <div class="pt-4 text-center">
            <h5 class="title">
              <span class="d-block mb-1">Rene Rangel</span>
              <small class="h6 text-muted">Technology Architect</small>
            </h5>
            <div class="mt-3">
              <a href="javascript:void(0)" class="btn btn-primary btn-icon-only rounded-circle">
                <i class="fa fa-twitter"></i>
              </a>
              <a href="javascript:void(0)" class="btn btn-primary btn-icon-only rounded-circle">
                <i class="fa fa-facebook"></i>
              </a>
              <a href="javascript:void(0)" class="btn btn-primary btn-icon-only rounded-circle">
                <i class="fa fa-dribbble"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--
  <section class="section-lg">
    <div class="position-relative">
      <div class="container">
        <div class="ui-container hero-module">
          <div
            class="about-hero-wrapper dynamic-height initiative program has-title has-intro has-cta has-texts has-breadcrumb"
            id="about-hero" data-analytics-module-name="aboutheroblock" data-analytics-template-zone="hero">
            <div class="item bg-color-white hero-initiative-program lazy module-container">

              <div class="row">
                <div class="block-content no-padding-topbottom" id="">
                  <div class="col-xs-12">
                    <article class="module">

                      <div class="col-xs-12 col-sm-6 col-md-6 hero-content">

                        <h1 class="rte-inline col-xs-12 page-title page-title-long">ForeTX en México</h1>

                        <p class="rte-inline col-xs-12 about-hero-intro corporate-regular">
                          Somos una empresa de tecnología y ofrecemos servicios de consultaria de tecnología enfocada en
                          Cloud, por ello buscamos innovar, proponer y desarollar mejores modelos de cloud que les faciliten a
                          nuestros clientes crecer de manera eficente y rentable.
                        </p>
                        <p class="rte-inline col-xs-12 about-hero-intro corporate-regular">
                          &nbsp;&nbsp;&nbsp;
                        </p>
                        <p class="rte-inline col-xs-12 about-hero-intro corporate-regular">
                          &nbsp;&nbsp;&nbsp;
                        </p>
                        <p class="rte-inline col-xs-12 about-hero-intro corporate-regular">
                          &nbsp;&nbsp;&nbsp;
                        </p>

                      </div>
                    </article>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 hidden-xs dynamic-bg image-background-banner"></div>
              </div>
            </div>
          </div>
        </div>
        <div id="block-sectionpagezoneonecolumn" class="ui-container lazy  bg-color-gray-lightest no-padding-topbottom"
          data-src="" data-analytics-template-zone="block-sectionpagezoneonecolumn">
          <div class="row no-padding-topbottom">
            <div class="block-content-second col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-lg-offset-0 col-md-offset-0 col-sm-offset-0">
                <div class="col-sm-12 col-xs-12">
                  <article class="module blockquote-container enable-border " data-analytics-module-name="blockquotemodule">
                    <blockquote class="quote-border solid-color-aqua">
                      <div class="quote-description rte-inline">ForeTX&nbsp;adopta el poder de la tecnología para crear valor
                        de 360° y éxito compartido para nuestros clientes, personas, socios y comunidades.
                      </div>
                    </blockquote>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="block-our-core-values" class="ui-container lazy bg-color-white" data-src=""
          data-analytics-template-zone="block-nuestros-valores">
          <div class="row ">
            <div class="col-xs-12">
              <div class="block-title">
                <h2 class="section-title col-lg-8 col-md-8 col-sm-12 col-xs-12">Nuestros valores
                  <hr>
                </h2>
              </div>
              <div class="col-xs-12">
                <div class="pagezone-description rte-inline col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  <p>Nuestros valores son los pilares de nuestra cultura como empresa y definen su carácter. Estos valores
                    se materializan en nuestro comportamiento a nivel individual y en la forma en
                    la que trabajamos.</p>
                </div>
              </div>

              <div class="block-content col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-lg-offset-0 col-md-offset-0 col-sm-offset-0">

                  <div class="progressive col-xs-12 first-load" data-cards-xs="" data-cards-sm="" data-cards-lm=""
                    data-card-count="6">
                    <div class="stat-card-block-container module block-adjustment  col-sm-12 col-xs-12">

                      <div class="stat-cards card-adjustment col-sm-12">

                        <article class="stat-card-container light col-sm-6 col-md-4 col-xs-12"
                          data-analytics-module-name="card1">
                          <div class="text-info-card progressive-card brand-color">

                            <div class="stat-content bg-color-gray-lightest">
                              <h3 class="module-title">Creación de valor para el cliente</h3>

                              <p class="fluid rte-inline">
                                Ayudar a nuestros clientes para que se conviertan en empresas de alto rendimiento y crear
                                relaciones a largo plazo, aportando valor a través de la innovación constantemente.
                              </p>
                            </div>
                          </div>
                        </article>

                        <article class="stat-card-container light col-sm-6 col-md-4 col-xs-12"
                          data-analytics-module-name="card3">
                          <div class="text-info-card progressive-card brand-color">

                            <div class="stat-content bg-color-gray-lightest">
                              <h3 class="module-title">Trabajo en equipo y colaboración</h3>

                              <p class="fluid rte-inline">
                                Co-crear soluciones juntos y fomentando la colaboración y trabajo en equipo de manera
                                constante para ofrecer servicio excepcional a los clientes.
                              </p>
                            </div>
                          </div>
                        </article>

                        <article class="stat-card-container light col-sm-6 col-md-4 col-xs-12"
                          data-analytics-module-name="card5">
                          <div class="text-info-card progressive-card brand-color">

                            <div class="stat-content bg-color-gray-lightest">
                              <h3 class="module-title">Transparencia</h3>

                              <p class="fluid rte-inline">
                                Fomentar un entorno de confianza donde todos puedan expresar ideas libremente. Promoviendo la
                                inclusión y reconociendo las contribuciones.
                              </p>
                            </div>
                          </div>
                        </article>

                        <article class="stat-card-container light col-sm-6 col-md-4 col-xs-12"
                          data-analytics-module-name="card2">
                          <div class="text-info-card progressive-card">
                            <div class="stat-band bg-color-card-brand"></div>
                            <div class="stat-content bg-color-gray-lightest">
                              <h3 class="module-title">Los mejores profesionales</h3>
                              <p class="fluid rte-inline">Atraer, desarrollar y retener a los mejores talentos para nuestra
                                empresa.Proporcionando las herramientas a nuestros profesionales para ayudarlos a alcanzar su
                                máximo potencial.</p>
                            </div>
                          </div>
                        </article>

                        <article class="stat-card-container light col-sm-6 col-md-4 col-xs-12"
                          data-analytics-module-name="card4">
                          <div class="text-info-card progressive-card">
                            <div class="stat-band bg-color-card-brand"></div>
                            <div class="stat-content bg-color-gray-lightest">
                              <h3 class="module-title">Integridad</h3>

                              <p class="fluid rte-inline">
                                Promover un entorno de confianza, transparencia y honestidad, asumiendo la responsabilidad de
                                nuestras acciones.
                              </p>
                            </div>
                          </div>
                        </article>

                        <article class="stat-card-container light col-sm-6 col-md-4 col-xs-12"
                          data-analytics-module-name="card6">
                          <div class="text-info-card progressive-card">
                            <div class="stat-band bg-color-card-brand"></div>
                            <div class="stat-content bg-color-gray-lightest">
                              <h3 class="module-title">Eficiencia</h3>

                              <p class="fluid rte-inline">
                                Combinar el talento humano, herramientas y tecnología de vanguardia para abordar los desafíos
                                mas complejos.
                              </p>
                            </div>
                          </div>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
-->
