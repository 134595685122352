<!-- navbar -->
<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
 <!--
<nav class="navbar navbar-main navbar-expand-lg navbar-transparent navbar-light headroom headroom--top headroom--pinned" id="navbar-main">
-->
  <div class="wrapper">
    <a class="navbar-brand" href="#">
      <img src="./assets/img/fore_tx_banner_logo.png" alt="">
    </a>
  </div>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar_global"
        aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse slidingNav" id="navbar_global">
      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a  href="#/home"><img src="./assets/img/fore_tx_logo.png"></a>
          </div>
          <div class="col-6 collapse-close">
            <button aria-controls="navbar_global" class="navbar-toggler" type="button" aria-expanded="false" data-toggle="collapse" data-target="#navbar_global">
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>
      <ul class="navbar-nav">
            <li [routerLinkActive]="['active']" class="nav-item">
                <a class="nav-link" [routerLink]="['/home']">Home <span class="sr-only">(current)</span></a>
            </li>
            <!--
            <li [routerLinkActive]="['active']" class="nav-item">
                <a class="nav-link" [routerLink]="['/insights']">Insights</a>
            </li>
          -->
            <li [routerLinkActive]="['active']" class="nav-item">
                <a class="nav-link" [routerLink]="['/services']">Services</a>
            </li>
            <li [routerLinkActive]="['active']" class="nav-item">
              <a class="nav-link" [routerLink]="['/industries']">Industries</a>
          </li>
            <li [routerLinkActive]="['active']" class="nav-item">
                <a class="nav-link" [routerLink]="['/about']">About ForeTX</a>
            </li>
            <li [routerLinkActive]="['active']" class="nav-item">
              <a class="nav-link" [routerLink]="['/contact']">Contact Us</a>
          </li>
      </ul>

    </div>
</nav>
