<div class="wrapper">
  <div class="page-header page-header-small header-filter">
    <div class="page-header-image background-industries">
      <div class="content-center">
        <div class="row">
          <div class="col-md-6 mx-auto text-center">
            <h1 class="title text-white"> </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main main-raised">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 mr-auto text-left mt-4">
          <div class="card bg-dark shadow border-0">
            <img src="assets/img/industries/retail.jpeg" class="card-img-top">
            <blockquote class="card-blockquote">
              <svg xmlns="http://www.w3.org/2000/svg" preserveaspectratio="none" viewbox="0 0 583 95" class="svg-bg">
                <polygon points="0,52 583,95 0,95" class="fill-dark"></polygon>
                <polygon opacity=".2" points="0,42 583,95 683,0 0,95" class="fill-darker"></polygon>
              </svg>
              <h4 class="display-4 text-white">Retail</h4>
              <p class="lead text-italic text-white">Our offer in retail digital transformation, we focus on shopping
                experience across digital channels, so consumers can shop anywhere and at any time. </p>
              <a href="industries#sectionRetail" class="btn btn-link text-white px-0">
                Read more </a>
            </blockquote>
          </div>
        </div>
        <div class="col-lg-4 mr-auto text-left mt-4">
          <div class="card bg-dark shadow border-0">
            <img src="assets/img/industries/cat_trucks_2.png" class="card-img-top">
            <blockquote class="card-blockquote">
              <svg xmlns="http://www.w3.org/2000/svg" preserveaspectratio="none" viewbox="0 0 583 95" class="svg-bg">
                <polygon points="0,52 583,95 0,95" class="fill-dark"></polygon>
                <polygon opacity=".2" points="0,42 583,95 683,0 0,95" class="fill-darker"></polygon>
              </svg>
              <h4 class="display-4 text-white">Consumers Goods and Services</h4>
              <p class="lead text-italic text-white">We are helping food and beverage companies pivot to consumers
                evolving needs for quick, healthy, socially responsible products that elevate experiences. </p>
              <a href="industries#sectionGoods&Services" class="btn btn-link text-white px-0">
                Read more </a>
            </blockquote>
          </div>
        </div>
        <div class="col-lg-4 mr-auto text-left mt-4">
          <div class="card bg-dark shadow border-0">
            <img src="assets/img/industries/hotel-5.jpeg" class="card-img-top">
            <blockquote class="card-blockquote">
              <svg xmlns="http://www.w3.org/2000/svg" preserveaspectratio="none" viewbox="0 0 583 95" class="svg-bg">
                <polygon points="0,52 583,95 0,95" class="fill-dark"></polygon>
                <polygon opacity=".2" points="0,42 583,95 683,0 0,95" class="fill-darker"></polygon>
              </svg>
              <h4 class="display-4 text-white">Travel and Experiences</h4>
              <p class="lead text-italic text-white">Travel companies need to reposition by activating a new strategy thinking in the travel Customer
              whose expectations and preferences will drive demand.
              </p>
              <a href="industries#sectionTravel" class="btn btn-link text-white px-0">
                Read more </a>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section features-3 my-5" style="background-image: url('/assets/img/ill/p31.svg');" id="sectionRetail">
    <div class="box text-center">
      <div class="icon icon-shape bg-primary icon-xl rounded-circle text-white">
      <i class="ni ni-cart"></i>
      </div>
    </div>
    <div class="container">
      <div class="row text-center justify-content-center">
        <div class="col-lg-8">
          <h3 class="display-3 text-white"><span class="text-success"> Retail Industry</span>
          </h3>
          <p class="lead text-white"> Get ready to transform the way your business perform retail. Increase sales and improve image, thanks to innovative initiatives by aiming at better customer's shopping experiences.</p>
        </div>
      </div>
      <div class="row row-grid mt-5">
        <div class="col-lg-4">
          <div class="info bg-white">
            <div class="icon icon-shape rounded-circle "><i class="ni ni-atom text-black"></i>
            </div>
            <div class="description pl-1">
              <h5 class="title text-black-50">smart store</h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="info bg-white">
            <div class="icon icon-shape rounded-circle "><i class="ni ni-satisfied text-black"></i>
            </div>
            <div class="description pl-1">
              <h5 class="title text-black-50">customer engagement</h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="info bg-white">
            <div class="icon icon-shape rounded-circle "><i class="ni ni-bulb-61 text-black"></i>
            </div>
            <div class="description pl-1">
              <h5 class="title text-black-50">client intelligence</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-grid">
        <div class="col-lg-4">
          <div class="info bg-white">
            <div class="icon icon-shape rounded-circle "><i class="ni ni-delivery-fast text-black"></i>
            </div>
            <div class="description pl-1">
              <h5 class="title text-black-50">supply chain</h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="info info-horizontal bg-transparent">
            <div class="description pl-1 text-center">
              <h5 class="title text-white">Redefining the distribution & retail industry</h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="info bg-white">
            <div class="icon icon-shape rounded-circle "><i class="ni ni-ui-04 text-black"></i>
            </div>
            <div class="description pl-1">
              <h5 class="title text-black-50">channel management</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-grid">
        <div class="col-lg-4">
          <div class="info bg-white">
            <div class="icon icon-shape rounded-circle "><i class="ni ni-building text-black"></i>
            </div>
            <div class="description pl-1">
              <h5 class="title text-black-50">corporate services</h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="info bg-white">
            <div class="icon icon-shape rounded-circle "><i class="ni ni-umbrella-13 text-black"></i>
            </div>
            <div class="description pl-1">
              <h5 class="title text-black-50">IT services</h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="info bg-white">
            <div class="icon icon-shape rounded-circle "><i class="ni ni-user-run text-black"></i>
            </div>
            <div class="description pl-1">
              <h5 class="title text-black-50">process optimization</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section features-3 my-5" style="background-image: url('/assets/img/ill/p31.svg');" id="sectionGoods&Services">
    <div class="box text-center">
      <div class="icon icon-shape bg-primary icon-xl rounded-circle text-white">
      <i class="ni ni-box-2"></i>
      </div>
    </div>
    <div class="container">
      <div class="row text-center justify-content-center">
        <div class="col-lg-8">
          <h3 class="display-3 text-white"><span class="text-success"> Consumers Goods and Service Industry</span>
          </h3>
          <p class="lead text-white"> Anticipate customer needs in a changing environment by providing tools to track and provision goods and services along with data reporting and metrics.</p>
        </div>
      </div>
      <div class="row row-grid mt-5">
        <div class="col-lg-4">
          <div class="info bg-white">
            <div class="icon icon-shape rounded-circle "><i class="ni ni-delivery-fast text-black"></i>
            </div>
            <div class="description pl-1">
              <h5 class="title text-black-50">supply chain</h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="info bg-white">
            <div class="icon icon-shape rounded-circle "><i class="ni ni-vector text-black"></i>
            </div>
            <div class="description pl-1">
              <h5 class="title text-black-50">innovation</h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="info bg-white">
            <div class="icon icon-shape rounded-circle "><i class="ni ni-mobile-button text-black"></i>
            </div>
            <div class="description pl-1">
              <h5 class="title text-black-50">digital</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section features-3 my-5" style="background-image: url('/assets/img/ill/p31.svg');" id="sectionTravel">
    <div class="box text-center">
      <div class="icon icon-shape bg-primary icon-xl rounded-circle text-white">
      <i class="ni ni-compass-04"></i>
      </div>
    </div>
    <div class="container">
      <div class="row text-center justify-content-center">
        <div class="col-lg-8">
          <h3 class="display-3 text-white"><span class="text-success"> Travel Industry</span>
          </h3>
          <p class="lead text-white">It's all about truly travel experience. </p>
        </div>
      </div>
      <div class="row row-grid mt-5">
        <div class="col-lg-4">
          <div class="info bg-white">
            <div class="icon icon-shape rounded-circle "><i class="ni ni-diamond text-black"></i>
            </div>
            <div class="description pl-1">
              <h5 class="title text-black-50">signature experience</h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="info bg-white">
            <div class="icon icon-shape rounded-circle "><i class="ni ni-watch-time text-black"></i>
            </div>
            <div class="description pl-1">
              <h5 class="title text-black-50">management efficiency</h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="info bg-white">
            <div class="icon icon-shape rounded-circle "><i class="ni ni-square-pin text-black"></i>
            </div>
            <div class="description pl-1">
              <h5 class="title text-black-50">digital</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
