<!-- header section-->
<section id="global-header-block">
    <app-header></app-header>
</section>
<!-- content will be rendered here -->
<section id="design-main" class="body-content " role="main">
    <router-outlet></router-outlet>
</section>


<!-- footer -->
<app-footer></app-footer>