import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule} from './app-routing.module';

import { AppComponent } from './app.component';
import { ServicesPageComponent } from './services-page/services-page.component';
import { AboutUsPageComponent } from './about-us-page/about-us-page.component';
import { ContactUsPageComponent } from './contact-us-page/contact-us-page.component';
import { InsightsPageComponent } from './insights-page/insights-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
//import { library } from '@fortawesome/fontawesome-svg-core';
import { faFilm } from '@fortawesome/free-solid-svg-icons';;
import { faTwitter, faFacebook,faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { IndustriesPageComponent } from './industries-page/industries-page.component';

@NgModule({
  declarations: [
    AppComponent,
    ServicesPageComponent,
    AboutUsPageComponent,
    ContactUsPageComponent,
    InsightsPageComponent,
    HomePageComponent,
    HeaderComponent,
    FooterComponent,
    IndustriesPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { 
  /** 
  constructor() {
    library.add(faFilm, faTwitter, faFacebook);
  }*/
  constructor(private library: FaIconLibrary) {
    library.addIcons(faLinkedin, faTwitter, faFacebook);
  }
}
